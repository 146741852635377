$.fn.extend({
    'notificationBar': function (this: JQuery) {
        const $self = this
        if (arguments.length >= 1 && typeof arguments[0] == 'string') {
            const command = arguments[0]
            const params = []
            for (let i = 1; i < arguments.length; i++) {
                params.push(arguments[i])
            }
            execCommand(command, params)
        } else {
            const options = arguments.length >= 1 ? arguments[0] : {}
            init(options)
        }

        function init(options) {
            options = $.extend({
                seenCookieName: 'seen-notifications',
            }, options)

            const bar = new NotificationBar($self, options.seenCookieName)
            $self.data('notificationBar', bar)
            return bar
        }

        function execCommand(command, params) {
            let bar = $self.data('notificationBar')
            if (!bar) {
                bar = init({})
            }
            bar.onCommand(command, params)
        }
    },
})

class NotificationBar {
    private version: string = "1.0"  // for data compatibility
    private maxStoredSeenMessageKeys = 10
    private seenMessageCookieLifetime = 365

    private readonly element: JQuery
    private readonly seenCookieName: string

    private readonly seenNotificationKeys

    constructor($element: JQuery, seenCookieName) {
        this.element = $element
        this.seenCookieName = seenCookieName

        // internal
        this.seenNotificationKeys = this.loadSeenNotificationKeys()
        if (this.seenNotificationKeys.length > 0) {
            // 延命
            this.saveSeenNotificationKeys(this.seenNotificationKeys)
        }

        return this
    }

    onCommand(command, params) {
        switch (command) {
            case 'push':
                this.push(params[0], params[1])
                break
            case 'close':
                this.closeMessageBox(params[0])
                break
            default:
                break
        }
    }

    push(key, message) {
        if (this.seenNotificationKeys.indexOf(key) === -1) {
            this.appendMessageBox(key, message)
        }
    }

    loadSeenNotificationKeys() {
        const v = Cookies.get(this.seenCookieName)
        if (!v) {
            return []
        }
        try {
            const o = JSON.parse(v)
            if (!(o.v === this.version && o.keys instanceof Array)) {
                Cookies.remove(this.seenCookieName)
                return []
            }
            return o.keys
        } catch (e) {
            Cookies.remove(this.seenCookieName)
            return []
        }
    }

    saveSeenNotificationKeys(keys) {
        const o = {
            v: this.version,
            keys: keys,
        }
        Cookies.set(this.seenCookieName, JSON.stringify(o), {
            expires: this.seenMessageCookieLifetime,
        })
    }

    storeSeenMessageKey(key) {
        // sync
        const newestKeys = this.loadSeenNotificationKeys()
        for (let i = newestKeys.length - 1; i >= 0; i--) {
            if (this.seenNotificationKeys.indexOf(newestKeys[i]) === -1) {
                this.seenNotificationKeys.unshift(newestKeys[i])
            }
        }
        // add
        this.seenNotificationKeys.unshift(key)
        // limit
        while (this.seenNotificationKeys.length > this.maxStoredSeenMessageKeys) {
            this.seenNotificationKeys.pop()
        }
        // save
        this.saveSeenNotificationKeys(this.seenNotificationKeys)
    }

    appendMessageBox(key, message) {
        if (!!this.findMessageBox(key)) {
            return
        }
        this.createMessageBox(key, message).appendTo(this.boxContainerElement())
    }

    closeMessageBox(key) {
        const $box = this.findMessageBox(key)
        if (!!$box) {
            $box.remove()
            this.storeSeenMessageKey(key)
        }
    }

    boxContainerElement() {
        const $root = $(this.element)
        let $container = $root.find('>.notification-bar-container')
        if ($container.length === 0) {
            $container = $('<div>').addClass('notification-bar-container').prependTo($root)
        }
        return $container
    }

    createMessageBox(key, message) {
        const self = this
        const $box = $('<div>').addClass('notification-bar-message-box').data('key', key)
        $('<button>').addClass('notification-bar-message-close').text("X").on('click', function () {
            self.closeMessageBox($(this).closest('.notification-bar-message-box').data('key'))
        }).appendTo($box)
        $('<div>').addClass('notification-bar-message-text').html(message).appendTo($box)
        return $box
    }

    findMessageBox(key) {
        const $container = this.boxContainerElement()
        const boxes = $container.find('.notification-bar-message-box')
        for (let i = 0; i < boxes.length; i++) {
            let $box = $(boxes.get(i))
            if ($box.data('key') === key) {
                return $box
            }
        }
        return null
    }
}
