interface IFrameResizePlugin {
    iFrameResize(options?: object): void
}

$.fn.extend({
    'zcommentResize': function (this: JQuery) {
        // options = $.extend({}, options);
        this.each(function () {
            const elements = $(this) as JQuery & IFrameResizePlugin
            elements.iFrameResize({
                heightCalculationMethod: 'bodyOffset',
                checkOrigin: false,
                minHeight: 350
            });
            registerMutationObserver(this);
        });
    },
})

function registerMutationObserver(element) {
    if (typeof MutationObserver === 'undefined') {
        return;
    }

    const observer = new MutationObserver(function (mutations) {
        for (let i in mutations) {
            let mutation = mutations[i];
            let oldFake = document.createElement('div');
            document.body.appendChild(oldFake);
            oldFake.setAttribute('style', mutation.oldValue);

            if (!(mutation.target instanceof HTMLElement)) {
                continue;
            }
            if (mutation.target.style.display !== oldFake.style.display) {
                let event;
                try {
                    event = new CustomEvent('resize');
                } catch (e) {
                    event = document.createEvent('CustomEvent');
                    event.initCustomEvent('resize', false, false, {});
                }
                window.dispatchEvent(event);
            }

            oldFake.parentElement.removeChild(oldFake);
        }
    });

    const attachObserver = iframe => {
        if (iframe && iframe.parentNode) {
            const target = iframe.parentNode;
            observer.observe(target, {attributes: true, attributeOldValue: true, attributeFilter: ['style']});
            attachObserver(target.parentNode);
        }
    };

    attachObserver(element);
}
