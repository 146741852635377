$.fn.extend({
    'anchorLink': function (this: JQuery, options) {
        options = $.extend({
            url: '',
        }, options)

        const headings = this.find(
            'h2:has(.anchor_super), h3:has(.anchor_super), h4:has(.anchor_super)',
        )

        headings.each(function (i, heading) {
            const id = $(heading).find('.anchor_super').attr('name')
            if (id) {
                const urlWithHash = options.url + '#' + id
                $(heading).addClass('heading-with-anchor-link').append(
                    '<a href="' + urlWithHash + '" class="anchor-link"><i class="fas fa-link"></i></a>'
                )
            }
        })
    },
})
