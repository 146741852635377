type ResourceCount = {
    all: number
    body: number
    others: number
}

export default function countResources(): ResourceCount {
    const counter = (
        selector: string,
        filterFunc: ($jq: JQuery) => boolean,
        uniqFunc: ($jq: JQuery) => string
    ): ($jq: JQuery) => number => {
        return (jqElm: JQuery) => {
            const keys = jqElm.find(selector).toArray()
                .filter((elem) => filterFunc($(elem)))
                .map(elem => uniqFunc($(elem)))
            return (new Set(keys)).size
        }
    }
    const imagesIn = counter('img',
        (jqElm) => {
            const src = jqElm.attr('src')
            return !(src === undefined || src.startsWith('data:') || src.includes('/common/'))
        },
        (jqElm) => jqElm.attr('src')
    )
    const iconsIn = counter('.user-icon',
        (jqElm) => true,
        (jqElm) => jqElm.attr('class')
    )

    const body = $('#body')
    const others = $('#header, #footer, #menubar, #sidebar')
    const all = body.add(others)

    const images = imagesIn(all) + iconsIn(all)
    if (images === 0) {
        return { all: 0, body: 0, others: 0 }
    }

    const bodyImages = imagesIn(body) + iconsIn(body)
    const othersImages = imagesIn(others) + iconsIn(others)

    return { all: images, body: bodyImages, others: othersImages }
}
