$.fn.extend({
    'fold': function (this: JQuery, options) {
        options = $.extend({
            buttonSelector: '',
            openClassName: '',
        }, options)

        const $toggleButton = this.children(options.buttonSelector)

        $toggleButton.on('click', function () {
            $(this).parent().toggleClass(options.openClassName)
        })
    },
})
