import {lazyDom} from './lib/lazy-dom'
import jsonp from 'smol-jsonp'

$.fn.extend({
    'embed': function (this: JQuery, options) {
        options = $.extend({
            rootMargin: '0px',
            requestType: 'fetch', // or jsonp
        }, options)

        lazyDom(this, function (elem) {
            const jq = $(elem)
            const placeholder = jq.find('.embed-placeholder')
            const codeRequestUrl = jq.data('url')
            if (!codeRequestUrl) {
                return
            }

            // jQuery の JSONP は中で何をされるかわからない。ランダムなクエリパラメータを付けたりされる。
            // ブラウザによっては XHR に書き換えられることもある。正確に制御したい通信には jQuery を利用しないほうがいい。
            const fetchFunction = options.requestType === 'jsonp' ? jsonp : fetchJSON

            placeholder.hide()
            fetchFunction(codeRequestUrl).then(function (data) {
                if (data.hasOwnProperty('code') && data.code) {
                    if (data.hasOwnProperty('aspectRatio') && data.aspectRatio) {
                        const container = jq.find('.embed-container')
                        container.addClass('embed-responsive')
                        container.css('padding-bottom', data.aspectRatio + '%')

                        const item = jq.find('.embed-item')
                        item.addClass('embed-responsive-item')
                    }
                    placeholder.html(data.code)
                    window.bootLazy?.bootOn(placeholder[0])
                }
            }).catch(function () {
                // nop
            }).finally(function () {
                placeholder.show()
            })
        }, options.rootMargin)
    },
})

async function fetchJSON(url: string): Promise<any> {
    const response = await fetch(url)
    return response.json()
}
