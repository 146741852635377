import { library } from '@fortawesome/fontawesome-svg-core'

// import {
//     faCheck as faCheckProLight,
// } from '@fortawesome/pro-light-svg-icons'
import {
    // faTextHeight as faTextHeightFreeSolid,
    // faBold as faBoldFreeSolid,
    // faItalic as faItalicFreeSolid,
    // faStrikethrough as faStrikethroughFreeSolid,
    // faUnderline as faUnderlineFreeSolid,
    // faHeading as faHeadingFreeSolid,
    // faListUl as faListUlFreeSolid,
    // faListOl as faListOlFreeSolid,
    // faAlignLeft as faAlignLeft,
    // faAlignCenter as faAlignCenter,
    // faAlignRight as faAlignRightFreeSolid,
    // faQuoteLeft as faQuoteLeftFreeSolid,
    // faTable as faTableFreeSolid,
    // faPaperclip as faPaperclipFreeSolid,
    // faWindowClose as faWindowCloseFreeSolid,
    // faUndo as faUndoFreeSolid,
    // faRedo as faRedoFreeSolid,
    // faCut as faCutFreeSolid,
    // faCopy as faCopyFreeSolid,
    // faSearch as faSearchFreeSolid,
    // faExclamationTriangle as faExclamationTriangleFreeSolid,
    // faExternalLink as faExternalLinkFreeSolid,
    // faLock as faLockFreeSolid,
    // faChevronLeft as faChevronLeftFreeSolid,
    // faChevronRight as faChevronRightFreeSolid,
    // faSignIn as faSignInFreeSolid,
    // faSignOut as faSignOutFreeSolid,
    // faHandPointRight as faHandPointRightFreeSolid,
    // faSync as faSyncFreeSolid,
    // faQuestionCircle as faQuestionCircleFreeSolid,
    // faFastBackward as faFastBackwardFreeSolid,
    // faCaretLeft as faCaretLeftFreeSolid,
    // faCaretRight as faCaretRightFreeSolid,
    // faCaretDown as faCaretDownFreeSolid,
    // faStepBackward as faStepBackwardFreeSolid,
    // faStepForward as faStepForwardFreeSolid,
    // faFilter as faFilterFreeSolid,
    // faEdit as faEditFreeSolid,
    faRectangleAd as faRectangleAdFreeSolid,
    faHouseFire as faHouseFireFreeSolid,
    faEyedropper as faEyedropperFreeSolid,
    // faAngleUp as faAngleUpFreeSolid,
} from '@fortawesome/free-solid-svg-icons'
import {
    // faHorizontalRule as faHorizontalRuleProSolid,
    faHatWizard as faHatWizardProSolid,
    faMessageExclamation as faMessageExclamationProSolid,
    faBlockQuestion as faBlockQuestionProSolid,
} from '@fortawesome/pro-solid-svg-icons'
// import {
//     faFaceLaugh as faFaceLaughFreeRegular,
// } from '@fortawesome/free-regular-svg-icons'
// import {
//     faExternalLink as faExternalLinkProRegular,
// } from '@fortawesome/pro-regular-svg-icons'
// import {
//     faYoutube as faYoutubeFreeBrands,
//     faTwitter as faTwitterFreeBrands,
// } from '@fortawesome/free-brands-svg-icons'

library.add(
    // faTextHeightFreeSolid,
    // faBoldFreeSolid,
    // faItalicFreeSolid,
    // faStrikethroughFreeSolid,
    // faUnderlineFreeSolid,
    // faHeadingFreeSolid,
    // faListUlFreeSolid,
    // faListOlFreeSolid,
    // faAlignLeft,
    // faAlignCenter,
    // faAlignRightFreeSolid,
    // faQuoteLeftFreeSolid,
    // faTableFreeSolid,
    // faHorizontalRuleProSolid,
    // faPaperclipFreeSolid,
    // faYoutubeFreeBrands,
    // faTwitterFreeBrands,
    // faFaceLaughFreeRegular,
    // faWindowCloseFreeSolid,
    // faUndoFreeSolid,
    // faRedoFreeSolid,
    // faCutFreeSolid,
    // faSearchFreeSolid,
    // faExternalLinkFreeSolid,
    // faLockFreeSolid,
    // faChevronLeftFreeSolid,
    // faChevronRightFreeSolid,
    // faSignInFreeSolid,
    // faSignOutFreeSolid,
    // faHandPointRightFreeSolid,
    // faSyncFreeSolid,
    // faQuestionCircleFreeSolid,
    // faCheckProLight,
    // faExternalLinkProRegular,
    // faFastBackwardFreeSolid,
    // faCaretLeftFreeSolid,
    // faCaretRightFreeSolid,
    // faCaretDownFreeSolid,
    // faStepBackwardFreeSolid,
    // faStepForwardFreeSolid,
    // faFilterFreeSolid,
    // faEditFreeSolid,
    faRectangleAdFreeSolid,  // アドマネの広告タグに含まれる
    faHatWizardProSolid,     // メンテのお知らせなどに使う
    faHouseFireFreeSolid,    // メンテのお知らせなどに使う
    faMessageExclamationProSolid,  // メンテのお知らせなどに使う
    faEyedropperFreeSolid,   // メンテのお知らせなどに使う
    faBlockQuestionProSolid, // メンテのお知らせなどに使う
    // faAngleUpFreeSolid
);
