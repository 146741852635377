$.fn.extend({
    'pageview': function (this: JQuery, options) {
        options = $.extend({
            counterSelector: '.wikiwiki-counter',
            counterSubComponentPrefix: '.wikiwiki-counter-',
            onlineSelector: '.wikiwiki-online',
        }, options)

        const $self = $(this)
        const endpoint = $(this).attr('data-endpoint')
        $.ajax({
            url: endpoint,
            success: function (data) {
                if (!!data) {
                    updateView(data)
                    triggerDataFetchedEvent(data)
                }
            },
        })

        function triggerDataFetchedEvent(data) {
            type DataEvent = JQuery.Event & Record<string, any>
            const event = jQuery.Event('pageview-data') as DataEvent
            event.today = data.today
            event.yesterday = data.yesterday
            event.total = data.total
            event.online = data.online
            $self.trigger(event)
        }

        function updateView(data) {
            const updateCounterField = function (selector, data, type) {
                if (data.hasOwnProperty(type)) {
                    $(selector).text(data[type])
                }
            }

            updateCounterField(options.counterSubComponentPrefix + 'today', data, 'today')
            updateCounterField(options.counterSubComponentPrefix + 'yesterday', data, 'yesterday')
            updateCounterField(options.counterSubComponentPrefix + 'total', data, 'total')
            updateCounterField(options.onlineSelector, data, 'online')
        }
    },
})

/**
 * $('#same-element').on('pageview-data', function(event) { ... });
 * とすると Ajax 取得成功後に event に { today: 123, yesterday: 456, total: 789, online: 10} 形式で
 * 取れるデータが来るはず。
 */
