"use strict";

const globalName = 'DisableSubmit';
if(!window[globalName]) {
    window[globalName] = applyDisabler;
}

/**
 * DOM 内の、フォームサブミットを発生させると思われる <input type="submit"> や <button> を disabled にする。
 * React が生成する DOM にも適用するため MutationObserver で変更を検知したときにも適用する。
 * なお fold プラグインなど挙動を妨げたくない <button> のもあるので、無効化するのは <form> の参照を持つ要素に限る。
 */
function applyDisabler(container: HTMLElement) {

    const disableSubmit = (node: Node) => {
        if (node instanceof HTMLInputElement) {
            if (node.type.toLowerCase() === 'submit' && !!node.form && !node.disabled) {
                node.disabled = true
            }
        } else if (node instanceof HTMLButtonElement) {
            if (!!node.form && !node.disabled) {
                node.disabled = true
            }
        }
        if (node.hasChildNodes()) {
            node.childNodes.forEach((child) => {
                disableSubmit(child)
            })
        }
    }

    const observer = new MutationObserver((records) => {
        records.map((record) => {
            if (record.type === 'childList' && record.addedNodes.length > 0) {
                record.addedNodes.forEach((node) => {
                    disableSubmit(node)
                })
            } else if (record.type === 'attributes') {
                disableSubmit(record.target)
            }
        })
    })
    observer.observe(container, { attributes: true, childList: true, subtree: true });

    disableSubmit(container)
}
