type ExpandFunction = (element: Element) => void

// JQuery の部分型でもある
interface ElementCollection {
    each(func: (this: Element) => void): void
}

export function lazyDom(elements: ElementCollection, expand: ExpandFunction, rootMargin: string) {
    if (!IntersectionObserver) {
        elements.each(function () {
            expand(this)
        })
        return
    }

    const observer = new IntersectionObserver(function (entries, observer) {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                // console.log(entry)
                expand(entry.target)
                observer.unobserve(entry.target)
            }
        }
    }, {
        root: null,
        rootMargin: rootMargin,
        threshold: 0,
    })

    elements.each(function () {
        observer.observe(this)
    })
}
