$.fn.extend({
    'pukiwiki': function (this: JQuery) {
        /**
         * open_uri(href, frame) の挙動を再現
         */
        this.on('click', '.pukiwiki-open-uri[data-href][data-frame]', function () {
            const self = $(this)
            const href = self.data('href')
            const frame = self.data('frame')
            if (!!frame) {
                window.open(href, frame)
            }
            return false
        })
    },
})
