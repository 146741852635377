const version = '1'

$.fn.extend({
    'browsingStatistics': function (this: JQuery, options) {
        options = $.extend({
            keyName: '',
        }, options)
        // const $self = $(this)

        if (typeof (Storage) === 'undefined') {
            return
        }

        const data = loadStatistics(options.keyName)
        updateTimes(data)
        writeStatistics(options.keyName, data)
    },
})

function loadStatistics(keyName: string) {
    let data
    try {
        data = JSON.parse(localStorage.getItem(keyName))
    } catch (e) {
        data = null
    }
    if (!data) {
        data = {}
    }

    if (!data.hasOwnProperty('version') || data.version !== version) {
        data.stay = 0
        data.visit = null
    } else {
        if (!data.hasOwnProperty('stay')) {
            data.stay = 0
        }
        if (!data.hasOwnProperty('visit')) {
            data.visit = null
        }
    }
    data.version = version

    return data
}

function writeStatistics(keyName, data) {
    localStorage.setItem(keyName, JSON.stringify(data))
}

function updateTimes(data) {
    const MAX_STAY_INCREMENT_SEC = 300

    const now = Math.floor(new Date().getTime() / 1000)
    const lastVisit = data.visit
    if (lastVisit != null) {
        const stay_time = now - lastVisit
        data.stay += Math.min(stay_time, MAX_STAY_INCREMENT_SEC)
        // すごい久しぶりの訪問ではぱっと最初に +300sec される
    }

    data.visit = now
}
