<script lang="ts">
    import cssProp2Str from '@shared/util/cssProp2Str'
    import {Properties} from 'csstype'

    export let url: string

    export let message: string = 'この項目を設定する（管理者限定）'
    export let style: Properties = {}

    let tooltipTarget: HTMLElement

    function tooltip(node: HTMLElement) {
        const tooltip = window.tippy(node, {
            content: message,
        })

        return {
            destroy() {
                tooltip.destroy()
            }
        }
    }
</script>

<a href="{url}" target="_blank" rel="noreferrer" style={cssProp2Str(style)}
   bind:this={tooltipTarget} use:tooltip={tooltipTarget}>
    <i class="fa-solid fa-wrench"></i>
</a>

<style>
    a {
        color: #888;
        font-size: 12px;
        margin: -3px -8px 0 0;
    }
</style>
