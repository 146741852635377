import {IconDefinition, library} from '@fortawesome/fontawesome-svg-core'
import md5 from "md5";

export async function faUseIcons(htmlClasses: string[]) {
    let imports = [] as Promise<IconDefinition|null>[]
    for (let htmlClass of htmlClasses) {
        for (let {type,name} of analyzeHtmlClass(htmlClass)) {
            imports.push(loadDefinition(type, name))
        }
    }
    const result = await Promise.all(imports)
    const iconDefinitions = result.filter((def: IconDefinition|null) => def !== null) as IconDefinition[]
    library.add(...iconDefinitions)
}

function analyzeHtmlClass(htmlClass: string): {type: string, name: string}[]
{
    const classes = htmlClass.split(/\s+/)
    let type = 'solid'

    const prefix2type = {
        'fas': 'solid',
        'far': 'regular',
        'fab': 'brands',
        'fa-solid': 'solid',
        'fa-regular': 'regular',
        'fa-brands': 'brands',
    } as Record<string, string>

    const skipTypes = [
        'fa-thin', 'fa-light', 'fa-duotone',
        'fal', 'fad',
    ]

    // https://fontawesome.com/docs/web/style/style-cheatsheet
    const skipWords = [
        'fa-inverse',
        // sizing
        'fa-1x', 'fa-2x', 'fa-3x', 'fa-4x', 'fa-5x', 'fa-6x', 'fa-7x', 'fa-8x', 'fa-9x', 'fa-10x',
        'fa-2xs', 'fa-xs', 'fa-sm', 'fa-lg', 'fa-xl', 'fa-2xl',
        // fixed-width
        'fa-fw',
        // list 直接 <i> には付けないけど
        'fa-ul', 'fa-li',
        // rotating
        'fa-rotate-90', 'fa-rotate-180', 'fa-rotate-270',
        'fa-flip-horizontal', 'fa-flip-vertical', 'fa-flip-both', 'fa-rotate-by',
        // animating
        'fa-beat', 'fa-beat-fade', 'fa-bounce', 'fa-fade', 'fa-flip',
        'fa-shake', 'fa-spin', 'fa-spin-reverse', 'fa-spin-pulse',
        // border
        'fa-border',
        // pull
        'fa-pull-left', 'fa-pull-right',
        // stack 直接 <i> には付けないけど
        'fa-stack', 'fa-stack-1x', 'fa-stack-2x', 'fa-inverse',
        // duotone 専用
        'fa-swap-opacity',
        // accessibility
        'fa-sr-only', 'fa-sr-only-focusable',
        // ?????
        'fa-sharp',
    ]

    let detected = []
    for (let e of classes) {
        if (prefix2type.hasOwnProperty(e)) {
            type = prefix2type[e]
        } else if (!skipTypes.includes(e) && !skipWords.includes(e) && e.startsWith('fa-')) {
            let name = e.substring(3)
            detected.push({type, name})
        }
    }

    return detected;
}

type IconModule = ()=>Promise<{ definition: IconDefinition }>
type ShardedModule = ()=>Promise<{ default: Record<string, IconModule>}>

const imports = {
    'solid-0': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-0" */'./fa-index-solid-0'),
    'solid-1': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-1" */'./fa-index-solid-1'),
    'solid-2': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-2" */'./fa-index-solid-2'),
    'solid-3': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-3" */'./fa-index-solid-3'),
    'solid-4': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-4" */'./fa-index-solid-4'),
    'solid-5': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-5" */'./fa-index-solid-5'),
    'solid-6': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-6" */'./fa-index-solid-6'),
    'solid-7': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-7" */'./fa-index-solid-7'),
    'solid-8': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-8" */'./fa-index-solid-8'),
    'solid-9': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-9" */'./fa-index-solid-9'),
    'solid-a': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-a" */'./fa-index-solid-a'),
    'solid-b': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-b" */'./fa-index-solid-b'),
    'solid-c': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-c" */'./fa-index-solid-c'),
    'solid-d': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-d" */'./fa-index-solid-d'),
    'solid-e': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-e" */'./fa-index-solid-e'),
    'solid-f': ()=>import(/* webpackChunkName: "fontawesome/fa-index-solid-f" */'./fa-index-solid-f'),
    'regular-0': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-0" */'./fa-index-regular-0'),
    'regular-1': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-1" */'./fa-index-regular-1'),
    'regular-2': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-2" */'./fa-index-regular-2'),
    'regular-3': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-3" */'./fa-index-regular-3'),
    'regular-4': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-4" */'./fa-index-regular-4'),
    'regular-5': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-5" */'./fa-index-regular-5'),
    'regular-6': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-6" */'./fa-index-regular-6'),
    'regular-7': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-7" */'./fa-index-regular-7'),
    'regular-8': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-8" */'./fa-index-regular-8'),
    'regular-9': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-9" */'./fa-index-regular-9'),
    'regular-a': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-a" */'./fa-index-regular-a'),
    'regular-b': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-b" */'./fa-index-regular-b'),
    'regular-c': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-c" */'./fa-index-regular-c'),
    'regular-d': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-d" */'./fa-index-regular-d'),
    'regular-e': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-e" */'./fa-index-regular-e'),
    'regular-f': ()=>import(/* webpackChunkName: "fontawesome/fa-index-regular-f" */'./fa-index-regular-f'),
    'brands-0': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-0" */'./fa-index-brands-0'),
    'brands-1': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-1" */'./fa-index-brands-1'),
    'brands-2': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-2" */'./fa-index-brands-2'),
    'brands-3': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-3" */'./fa-index-brands-3'),
    'brands-4': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-4" */'./fa-index-brands-4'),
    'brands-5': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-5" */'./fa-index-brands-5'),
    'brands-6': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-6" */'./fa-index-brands-6'),
    'brands-7': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-7" */'./fa-index-brands-7'),
    'brands-8': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-8" */'./fa-index-brands-8'),
    'brands-9': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-9" */'./fa-index-brands-9'),
    'brands-a': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-a" */'./fa-index-brands-a'),
    'brands-b': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-b" */'./fa-index-brands-b'),
    'brands-c': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-c" */'./fa-index-brands-c'),
    'brands-d': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-d" */'./fa-index-brands-d'),
    'brands-e': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-e" */'./fa-index-brands-e'),
    'brands-f': ()=>import(/* webpackChunkName: "fontawesome/fa-index-brands-f" */'./fa-index-brands-f'),
} as Record<string, ShardedModule>

async function loadDefinition(iconType: string, iconName: string): Promise<IconDefinition|null> {
    let shardName = iconType + '-' + digest(iconName)
    if (!imports.hasOwnProperty(shardName)) {
        return null
    }
    const shardedImport = imports[shardName]
    const {default: shardedModule,} = await shardedImport()
    if (!shardedModule.hasOwnProperty(iconName)) {
        return null;
    }
    const iconModule = shardedModule[iconName]
    const {definition:iconDefinition,} = await iconModule()
    return iconDefinition
}

function digest(name: string) {
    return md5(name).charAt(0);
    // (await crypto.subtle.digest('SHA-1', name)).slice(0, 2).toString();
}
