import {formatDate, formatDateWeekdayTime, formatTime} from './lib/value-format'

$.fn.extend({
    'realtimeDate': function (this: JQuery, options) {
        options = $.extend({
            formats: {
                "time": formatTime,
                "date": formatDate,
                "date-weekday-time": formatDateWeekdayTime,
            },
        }, options)

        const $elements = this
        const datetimeUpdater = function () {
            const now = new Date()
            $elements.each(function () {
                const $date = $(this)
                const formatter = options.formats[$date.attr('data-format')]
                $date.text(formatter(now))
            })
        }
        datetimeUpdater()
        setInterval(datetimeUpdater, 1000)
    },
})
