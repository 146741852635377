import md5 from 'md5'

type BootFunction = (this: Element) => void
type JQueryBootFunction = (this: JQuery) => void

export class BootLazy {
    bootFunctions: Record<string, BootFunction> = {}

    private _uniqAdd(key: string, f: BootFunction) {
        if (!this.bootFunctions.hasOwnProperty(key)) {
            this.bootFunctions[key] = f
        }
    }

    // noinspection JSUnusedGlobalSymbols
    addPlainScript(f: BootFunction) {
        // 同じ「内容」の関数はひとつだけ登録する (ベタ書きのみ。高階間数は扱えない)
        const key = md5(f.toString())
        this._uniqAdd(key, f)
    }

    addJqPluginInit(selector: string, jf: JQueryBootFunction) {
        // 同じ「内容」の関数はひとつだけ登録する (ベタ書きのみ。高階間数は扱えない)
        const key = md5(selector + ':' + jf.toString())
        this._uniqAdd(key, function () {
            jf.call(jQuery(this).find(selector))
        })
    }

    bootOn(element: Element) {
        // console.debug('bootOn', element, Object.keys(this.bootFunctions))
        for (const key in this.bootFunctions) {
            this.bootFunctions[key].call(element)
        }
    }
}
