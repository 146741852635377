$.fn.extend({
    'moved': function (this: JQuery, options) {
        // IE はサポートしない
        if (typeof URLSearchParams === 'undefined') {
            return this
        }

        options = $.extend({
            messageAreaSubSelector: '.message',
            pageContentsSelector: null,
            pageContentsDisappearedClass: null,
        }, options)

        return this.each(function () {
            var $self = $(this)
            var $messageArea = $self.find(options.messageAreaSubSelector)

            var currentPage = $self.data('current-page')
            var destinationUrl = $self.data('destination-url')
            var enableTransition = $self.data('enable-transition')

            var params = new URLSearchParams(location.search)
            var chain = params.get('chain')

            var items = chain ? chain.split(':') : []
            for (var i = 0; i < items.length; i++) {
                if (items[i] === currentPage) {
                    $messageArea.text('エラー：循環参照しています。')
                    return
                }
            }
            items.push(currentPage)

            const to = destinationUrl + '?chain=' + items.join(':')

            if (enableTransition) {
                setTimeout(function () {
                    location.assign(to)
                }, 10000)
                $messageArea.text('数秒後に自動的にジャンプします。')
            } else {
                // noinspection HtmlDeprecatedTag,XmlDeprecatedElement
                $messageArea.append($('<strike>').text('数秒後に自動的にジャンプします。'))
            }

            if (options.pageContentsSelector && options.pageContentsDisappearedClass) {
                $(options.pageContentsSelector).addClass(options.pageContentsDisappearedClass)
            }
        })
    },
})
