<script lang="ts">
    import cssProp2Str from '@shared/util/cssProp2Str'
    import {Properties} from 'csstype';
    import {onDestroy, onMount} from 'svelte'

    export let panelId: string

    export let trigger: 'click' | 'hover' = 'click'

    export let triggerColor: 'success' | 'safe' | 'danger' | 'warning' | '' = ''
    export let closeOnOutsideClick: boolean = true

    export let closeOnOtherPanelOpen: boolean = true
    export let style: Properties = {}

    let root: HTMLDivElement

    // まだ一度も表示されていないとき null。これで初期化時のコンテンツ DOM 構築の無駄を避ける。
    // (非表示にするときは display:none の方が速いし、再表示も効率的)
    let visibilityStatus: null|'visible'|'hidden' = null

    $: panelStyle = cssProp2Str({
        background: '#fff',
        position: 'absolute',
        display: visibilityStatus === 'visible' ? 'block' : 'none',
        zIndex: '9999',
        padding: '0.5rem 1rem',
        border: '#999 1px solid',
        borderRadius: '0.5rem',
        right: '16px',
        overflowY: 'auto',
        color: 'black',
        ...style,
    })

    function show() {
        visibilityStatus = 'visible'
        window.dispatchEvent(new CustomEvent('floatingPanelShow', {detail: panelId}))
    }

    function hide() {
        if (visibilityStatus !== null) {
            visibilityStatus = 'hidden'
        }
    }

    function toggle() {
        if (visibilityStatus === 'visible') {
            hide()
        } else {
            show()
        }
    }

    function handleClickOutside(event: MouseEvent) {
        if (closeOnOutsideClick && root && !root.contains(event.target as Node)) {
            hide()
        }
    }

    function handleOtherPanelOpen(e) {
        if (closeOnOtherPanelOpen && e.detail !== panelId) {
            hide()
        }
    }

    onMount(() => {
        window.addEventListener('floatingPanelShow', handleOtherPanelOpen);
    });

    onDestroy(() => {
        window.removeEventListener('floatingPanelShow', handleOtherPanelOpen);
    });

</script>

<svelte:document on:click={handleClickOutside} />

<div class="floating-panel-container" bind:this={root}>
    {#if trigger === 'click'}
        <button on:click={toggle} class="trigger {triggerColor}">
            <slot name="trigger">Toggle Panel</slot>
        </button>
    {:else if trigger === 'hover'}
        <button on:mouseenter={show} on:mouseleave={hide} class="trigger {triggerColor}">
            <slot name="trigger">Hover Panel</slot>
        </button>
    {/if}

    <div class="floating-panel" style={panelStyle}>
        {#if visibilityStatus !== null}
            <slot name="content"/>
        {/if}
    </div>
</div>

<style>
    .floating-panel-container {
        & .trigger {
            cursor: pointer;
            background: none;
            border: none;
            color: #888;
            padding: 4px;

            &:hover {
                background-color: #dcdcdc;
            }

            &.danger {
                color: #dc3545;
            }
            &.warning {
                color: #FF7F00;
            }
            &.success, &.safe {
                color: #28a745;
            }
        }
    }
</style>
