import {lazyDom} from './lib/lazy-dom'

$.fn.extend({
    'lazyDom': function (this: JQuery, options) {
        options = $.extend({
            rootMargin: '0px',
        }, options)

        lazyDom(this, function (elem) {
            const jq = $(elem)
            jq.html(jq.data('content') ?? "no content")
            jq.attr('data-content', null)
            window.bootLazy?.bootOn(elem)
        }, options.rootMargin)
    },
});
