<script lang="ts">

import ControlPanelLink from './ControlPanelLink.svelte'
import FloatingPanel from './FloatingPanel.svelte'

export let logoImgUrl: string
export let pageTitle: string
export let controlPanelUrl: string
export let contact: string

const style = {
    width: '320px',
    maxHeight: '400px',
}
</script>

<FloatingPanel panelId="admin-contact" triggerColor={contact ? 'success' : ''} style={style}>
    <i slot="trigger" class="fas fa-envelope"></i>
    <div slot="content" class="admin-contact-tooltip">
        <div class="admin-contact-tooltip-header">
            <div class="admin-contact-tooltip-page-title">
                <img src={logoImgUrl} width="30" height="30" title={pageTitle} alt="Logo">
                <span>{pageTitle}</span>
            </div>
            <ControlPanelLink url={controlPanelUrl} />
        </div>
        <p><strong>管理者コンタクト</strong><i class="fas fa-envelope"></i></p>
        <p>記事の内容や運用についてのお問い合わせはこちらにお願いします。</p>
        {#if contact}
            <div class="admin-contact-tooltip-body">
                {contact}
            </div>
        {:else}
            <p style="color: #ccc;">未登録</p>
        {/if}
    </div>
</FloatingPanel>

<style>
    .admin-contact-tooltip {
        font-size: 14px;

        & .admin-contact-tooltip-header {
            display: flex;
            align-items: start;
            justify-content: space-between;

            & img {
                margin-right: 8px;
            }

            & .admin-contact-tooltip-page-title {
                font-size: 1.2em;
                font-weight: bold;
                max-width: 90%;
                display: flex;
                align-items: center;
            }
        }

        & .admin-contact-tooltip-body {
            margin-top: 8px;
            font-size: 0.8em;
            white-space: pre-line;
        }
    }
</style>
