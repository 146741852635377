$.fn.extend({
    'ntbr': function (this: JQuery) {
        // ntbr は「ネタバレ」のローマ字子音
        return $(this).each(function () {
            const self = $(this)
            let target = self

            const parent = self.parent()
            if (parent.is('td,th') && parent.contents().length === 1) {
                // FIXME class ハードコードどうなんか
                parent.addClass('plugin-ntbr')
                parent.css({
                    'background-color': self.css('background-color'),
                    'color': self.css('color'),
                })
                parent.attr('data-alt', self.attr('data-alt'))
                parent.append(self.children())
                self.remove()
                target = parent
            }

            target.on('click', function () {
                if (!target.is('.unveiled')) {
                    target.addClass('unveiled')
                    target.css({
                        'background-color': "",
                        'color': "",
                    })
                }
            })
        })
    },
})
