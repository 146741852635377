$.fn.extend({
    'closedOnNarrowScreen': function (this: JQuery, options) {
        options = $.extend({
            closedThreshold: 768,
            expanderSelector: null,
        }, options)

        function collapse(element: Element) {
            const jq = $(element)
            jq.html(jq.data('placeholder') ?? "no content")
            jq.attr('data-placeholder', null)
            window.bootLazy?.bootOn(element)
        }

        function expand(element: Element) {
            const jq = $(element)
            jq.html(jq.data('content') ?? "no content")
            jq.attr('data-content', null)
            jq.attr('data-placeholder', null)
            window.bootLazy?.bootOn(element)
        }

        if (window.innerWidth >= options.closedThreshold) {
            this.each(function () {
                expand(this)
            })
        } else {
            this.each(function () {
                collapse(this)
                const self = this
                let $expander = $(this)
                if (options.expanderSelector) {
                    $expander = $expander.find(options.expanderSelector)
                }
                $expander.one('click', function () {
                    expand(self)
                    return false
                })
            })
        }
    },
})
