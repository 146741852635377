import Cookies from 'js-cookie'
import md5 from "md5"

const COOKIE_KEY_PREFIX = 'navfold-'

const CONTAINER_CLASS = 'navfold-container'
const CONTROL_CLASS = 'navfold-summary'

const OPEN_CLASS = 'navfold-open'
const DEFAULT_OPEN_CLASS = 'navfold-default-open'

$.fn.extend({
    'navfold': function (this: JQuery, options) {
        options = $.extend({
            wiki: null,
            persistence: {},
        }, options)

        for (let persistenceKey in options.persistence) {
            let persistenceTargetSelector = options.persistence[persistenceKey]
            restoreNavFoldingStates(persistenceKey, $(persistenceTargetSelector))
        }

        controlElementOf(this).on('click', function (){
            const $container = containerElementOf($(this))
            if ($container.hasClass(OPEN_CLASS)) {
                $container.removeClass(OPEN_CLASS)
            } else {
                $container.addClass(OPEN_CLASS)
            }
            resetChildrenFolding($container)

            if (!options.wiki) {
                return
            }

            for (let persistenceKey in options.persistence) {
                let persistenceTargetSelector = options.persistence[persistenceKey]
                let $persistenceRoot = $(persistenceTargetSelector)
                if ($persistenceRoot.length > 0 && $.contains($persistenceRoot.get()[0], this)) {
                    storeNavFoldingStates(persistenceKey, $persistenceRoot, options.wiki)
                    break
                }
            }
        })
    }
})

function resetChildrenFolding($container: JQuery) {
    $container.find('.' + CONTAINER_CLASS).each(function () {
        const $item = $(this)
        if ($item.hasClass(DEFAULT_OPEN_CLASS)) {
            $item.addClass(OPEN_CLASS)
        } else {
            $item.removeClass(OPEN_CLASS)
        }
    })
}

function controlElementOf($container: JQuery) {
    return $container.find('>.' + CONTROL_CLASS)
}

function containerElementOf($control: JQuery) {
    return $control.closest('.' + CONTAINER_CLASS)
}

function navFoldPathOf($container: JQuery): string {
    const $parentContainer = $container.parent().closest('.' + CONTAINER_CLASS)
    const prefix = $parentContainer.length > 0 ? navFoldPathOf($parentContainer) + '/' : ''
    return prefix + controlElementOf($container).text()
}

function hashKey(path: string): string {
    return md5(path).substring(0, 12)
}

function storeNavFoldingStates(key: string, $root: JQuery, wiki: string) {
    let changedFolds: Record<string, boolean> = {}
    $root.find('.' + CONTAINER_CLASS).each(function () {
        const $item = $(this)
        if (!$item.hasClass(DEFAULT_OPEN_CLASS) && $item.hasClass(OPEN_CLASS)) {
            changedFolds[hashKey(navFoldPathOf($item))] = true
        } else if ($item.hasClass(DEFAULT_OPEN_CLASS) && !$item.hasClass(OPEN_CLASS)) {
            changedFolds[hashKey(navFoldPathOf($item))] = false
        }
    })
    // console.log(changedFolds)
    const cookieName = COOKIE_KEY_PREFIX + key
    const cookieOptions = {
        path: '/' + wiki,
    }
    if (Object.keys(changedFolds).length === 0) {
        Cookies.remove(cookieName, cookieOptions)
    } else {
        Cookies.set(cookieName, JSON.stringify(changedFolds), cookieOptions)
    }
}

function restoreNavFoldingStates(key: string, $root: JQuery) {
    let changedFolds
    try {
        changedFolds = JSON.parse(Cookies.get(COOKIE_KEY_PREFIX + key))
    } catch (e) {
        return
    }
    if (typeof changedFolds !== 'object') {
        return
    }
    // console.log(changedFolds)
    $root.find('.' + CONTAINER_CLASS).each(function () {
        const $item = $(this)
        const key = hashKey(navFoldPathOf($item))
        if (!changedFolds.hasOwnProperty(key)) {
            return
        }
        if (!$item.hasClass(DEFAULT_OPEN_CLASS) && changedFolds[key]) {
            $item.addClass(OPEN_CLASS)
        } else if ($item.hasClass(DEFAULT_OPEN_CLASS) && !changedFolds[key]) {
            $item.removeClass(OPEN_CLASS)
        }
    })
}
