<script lang="ts">
    import {onMount} from 'svelte'
    import {writable} from 'svelte/store'
    import {Instance} from 'tippy.js'
    import ControlPanelLink from './ControlPanelLink.svelte'

    export let title: string
    export let controlPanelUrl: string
    export let imageUrl: string
    export let description: string
    export let shareUrl: string
    export let shortUrlRequestUrl: string|null

    const EMPTY_DESCRIPTION = 'WIKIの説明が未設定です'

    let copyButton: HTMLButtonElement
    let sharePanelCopyTooltip: Instance

    let shortUrlDisabled = shortUrlRequestUrl == null

    const isShortUrlUsed = writable(false)
    const shortUrl = writable("")
    const displayedShareUrl = writable(shareUrl)

    onMount(() => {
        sharePanelCopyTooltip = window.tippy(copyButton, {
            content: 'タイトルとリンクをコピーしました',
            trigger: 'manual',
        })

        return () => {
            sharePanelCopyTooltip.destroy()
        }
    })

    function toggleShortUrl() {
        if ($shortUrl == "") {
            fetch(shortUrlRequestUrl).then((res) => res.json()).then((data) => {
                shortUrl.set(data.url)
                updateDisplayedShareUrl()
            }).catch((err) => {
                shortUrl.set("error")
                updateDisplayedShareUrl()
                console.error(err)
            })
        }
        isShortUrlUsed.update((v) => !v)
        updateDisplayedShareUrl()
    }

    async function updateDisplayedShareUrl() {
        if ($isShortUrlUsed) {
            if ($shortUrl != "") {
                return displayedShareUrl.set($shortUrl)
            } else {
                return displayedShareUrl.set("短縮URLを取得中...")
            }
        } else {
            return displayedShareUrl.set(shareUrl)
        }
    }

    function actualShareUrl(): string {
        return $isShortUrlUsed && $shortUrl != "" ? $shortUrl : shareUrl
    }

    function handleClickCopy() {
        if (!navigator.clipboard) {
            alert('このブラウザはクリップボードへのコピーに対応していません。お手数ですが、URLを手動でコピーしてください。')
            return
        }

        navigator.clipboard.writeText(`${title} ${actualShareUrl()}`).then(function() {
            sharePanelCopyTooltip.show()
        }).catch(function() {
            alert('クリップボードへのコピーに失敗しました。')
        })
    }

    function handleClickShare() {
        navigator.share({
            title,
            text: description,
            url: actualShareUrl(),
        })
    }
</script>

<div class="share-panel">
    <div class="share-panel-title">
        <span class="title-text">{title}</span>
        <ControlPanelLink url={controlPanelUrl} />
    </div>
    <img src="{imageUrl}" alt="OGPイメージ" class="share-panel-image" loading="lazy">
    <div class="share-panel-description" class:no-description={!description}>{description || EMPTY_DESCRIPTION}</div>
    <div class="share-panel-buttons">
        <div class="share-panel-share-url">{$displayedShareUrl}</div>
        <div class="share-panel-operations">
            <label class:disabled={shortUrlDisabled}>
                <input type="checkbox"
                    on:click={toggleShortUrl}
                    bind:checked={$isShortUrlUsed}
                    disabled={shortUrlDisabled}> 短縮URL</label>
            <div class="share-panel-sns-button">
                <button bind:this={copyButton} on:click={handleClickCopy}
                        type="button" class="share-panel-copy-button" title="クリップボードにコピー">
                    <i class="fa-solid fa-paperclip"></i> コピー
                </button>
                {#if navigator.share}
                    <button on:click={handleClickShare} type="button" class="share-panel-buttons native-share-button" title="共有">
                        <i class="fa-solid fa-share-from-square"></i> 共有
                    </button>
                {/if}
            </div>
        </div>
    </div>
</div>

<style>
    .share-panel {

        & .share-panel-title {
            font-size: 14px;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            align-items: start;
            margin-bottom: 8px;

            & .title-text {
                max-width: 90%;
            }
        }

        & .share-panel-buttons {
            padding: 4px;

            & .share-panel-share-url {
                font-size: 10px;
                flex: auto;
                width: 100%;
                word-break: break-all;
                border: solid 1px #aaa;
                background: #eee;
                padding: 4px;
            }

            & .share-panel-operations {
                display: flex;
                justify-content: space-between;
                align-items: center;

                & label {
                    margin: 0;
                }
                & label.disabled {
                    color: #ccc;
                }

                & .share-panel-sns-button {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    & button {
                        background: none;
                        border: none;
                        padding: 4px;
                    }
                }
            }
        }

        & .share-panel-description {
            font-size: 12px;
            margin-top: 8px;
            margin-bottom: 8px;

            &.no-description {
                color: #ccc;
            }
        }

        & .share-panel-image {
            max-width: 100%;
            height: auto;
            border: solid 1px #ccc;
            margin: auto;
            display: block;
        }
    }
</style>
