$.fn.extend({
    'randommes': function (this: JQuery, options: any) {
        options = $.extend({
            'url': '',
            'stackName': '__stack',
        }, options);

        this.each(function () {
            const $elem = $(this)

            const page = $elem.data('page')
            if (!page) {
                return
            }
            const queryParams = new URLSearchParams()
            queryParams.append('page', page)
            queryParams.append('mode', $elem.data('mode') ?? 'always')
            // jQuery.data() は JSON っぽい文字列をパースしてしまうので attr() で取得する
            queryParams.append(options.stackName, $elem.attr('data-stack-json') ?? '[]')
            const url = options.url + (options.url.match(/\?/) ? '&' : '?') + queryParams.toString()

            const spinnerDisplayTimeout = setTimeout(function () {
                $elem.html('<i class="fas fa-spinner fa-pulse"></i>')
            }, 1000)

            $.get(url).then(function (data) {
                clearTimeout(spinnerDisplayTimeout)
                $elem.html(data)
                window.bootLazy.bootOn($elem.get()[0])
            })
        })
    }
})
