import {Properties} from 'csstype';

export default function cssProp2Str(styles: Properties): string {
    return Object.entries(styles)
        .map(([key, value]) => {
            // キャメルケースをケバブケースに変換
            const kebabKey = key.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);

            return `${kebabKey}: ${value};`;
        })
        .join(' ');
}
