$.fn.extend({
    usageStatistics: function(this: JQuery, options) {
        const version = '2'
        const settings = $.extend({
            keyName: '',
            smsAuth: '',
            hasPosted: '',
        }, options)
        const $self = $(this)

        if (typeof (Storage) === 'undefined') {
            return
        }

        function loadStatistics() {
            let data
            try {
                data = JSON.parse(localStorage.getItem(settings.keyName))
            } catch (e) {
                data = null
            }
            if (!data) {
                data = {}
            }

            if (!data.hasOwnProperty('version') || data.version !== version) {
                data.edit = 0
                data.freeze = 0
                data.comment = 0
                data.group = 'N'
            } else {
                if (!data.hasOwnProperty('edit')) {
                    data.edit = 0
                }
                if (!data.hasOwnProperty('freeze')) {
                    data.freeze = 0
                }
                if (!data.hasOwnProperty('comment')) {
                    data.comment = 0
                }
                if (!data.hasOwnProperty('group')) {
                    data.group = 'N'
                }
            }
            data.version = version

            return data
        }

        function writeStatistics(data) {
            localStorage.setItem(settings.keyName, JSON.stringify(data))
        }

        function detectPluginName(url) {
            const match = /\?(cmd|plugin)=([^&]+)/.exec(url)
            if (!match) {
                return null
            }
            return match[2]
        }

        function freeze(data) {
            data.group = 'A'
            data.freeze++
        }

        function edit(data) {
            if (data.group !== 'A') {
                data.group = 'E'
            }
            data.edit++
        }

        function comment(data) {
            if (data.group !== 'A' && data.group !== 'E') {
                data.group = 'C'
            }
            data.comment++
        }

        const data = loadStatistics()
        data.smsAuth = settings.smsAuth
        data.hasPosted = settings.hasPosted
        writeStatistics(data)

        $self.on('submit', function () {
            const pluginName = detectPluginName($(this).attr('action'))
            if (!pluginName) {
                return
            }
            const data = loadStatistics()
            switch (pluginName) {
                case 'freeze':
                case 'unfreeze':
                    freeze(data)
                    break
                case 'edit':
                    edit(data)
                    break
                case 'pcomment':
                case 'comment':
                    comment(data)
                    break
                default:
                    return
            }
            writeStatistics(data)
        })
    },
})
