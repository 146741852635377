$.fn.extend({
    'refExtImageFix': function (this: JQuery, options) {
        options = $.extend({
            limit: 48,
            image: 'img',
            smallClass: 'ref-external-small',
        }, options)

        this.each(function (this: HTMLElement) {
            const container = $(this)
            const image = container.find(options.image) as JQuery<HTMLImageElement>
            const fix = function () {
                // 少し待たないとまだサイズが決まっていない可能性がある
                setTimeout(()=>{
                    // console.log(image.attr('src'))
                    if (image.width() <= options.limit || image.height() <= options.limit) {
                        container.addClass(options.smallClass)
                    } else {
                        container.removeClass(options.smallClass)
                    }
                }, 20)
            }
            if (image.get(0).complete) {
                fix()
            } else {
                image.one('load error', fix)
            }
            // 効果あるのか??
            image.on('resize', fix)
        })
    }
})
