import {zf2} from './lib/value-format'

$.fn.extend({
    'realtimeCountdown': function (this: JQuery) {
        const $elements = this
        const datetimeUpdater = function () {
            const now = new Date()
            $elements.each(function () {
                const elm = $(this)
                if (elm.data('stopped')) {
                    return
                }

                const limit = parseInt(elm.data('limit'))
                const mode = elm.data('mode')
                const dif = limit - (now.getTime() / 1000)
                if (dif < 0) {
                    elm.text(afterLimitMessage(elm.data('message'), mode))
                    elm.data('stopped', true)
                } else {
                    elm.text(beforeLimitMessage(dif, mode))
                }
            })
        }

        setInterval(datetimeUpdater, 1000)
    },
})

const DEFAULT_COMPLETION_MESSAGE = {
    day: "0日",
    hour: "0時間",
    full: "00:00:00",
}

function afterLimitMessage(message, mode): string {
    return message.toString() || DEFAULT_COMPLETION_MESSAGE[mode] || ""
}

function beforeLimitMessage(dif: number, mode) {
    const sec = Math.floor(dif % 60)
    const min = Math.floor((dif % 3600) / 60)
    const hour = Math.floor((dif % (3600 * 24)) / 3600)
    const day = Math.floor(dif / (3600 * 24))

    let message = ""
    if (mode === 'full') {
        if (day > 0) {
            message += day + "日と"
        }
        message += zf2(hour) + ":" + zf2(min) + ":" + zf2(sec)
    } else if (mode === 'day' && day > 0) {
        message += day + '日'
    } else if (day * 24 + hour > 0) {
        message += (day * 24 + hour) + '時間'
    } else if ((day * 24 + hour) * 60 + min > 0) {
        message += ((day * 24 + hour) * 60 + min) + '分'
    } else {
        message += (((day * 24 + hour) * 60 + min) * 60 + sec) + '秒'
    }
    return message
}
