$.fn.extend({
    'newmarker': function (this: JQuery, options) {
        options = $.extend({
            rules: [
                {
                    limit: 60 * 60 * 24,
                    mark: ' <span class="new1">New!</span>',
                },
                {
                    limit: 60 * 60 * 24 * 3,
                    mark: ' <span class="new3">New</span>',
                },
                {
                    limit: 60 * 60 * 24 * 5,
                    mark: ' <span class="new5">New</span>',
                },
            ],
        }, options)

        const now = new Date()

        this.each(function () {
            var $date = $(this)
            var targetDate = new Date(parseInt($date.attr('data-timestamp')) * 1000)
            for (var i = 0; i < options.rules.length; i++) {
                const elapsedSec = (now.getTime() - targetDate.getTime()) / 1000
                if (elapsedSec < options.rules[i].limit) {
                    const $mark = $(options.rules[i].mark)
                    $mark.attr('title', normalizeTimeSpanSec(elapsedSec))
                    $date.append($mark)
                    break
                }
            }
        })
    },
})

function normalizeTimeSpanSec(s) {
    const min = s / 60
    if (Math.abs(min) < 60) {
        return Math.floor(min) + "m"
    }
    const hour = min / 60
    if (Math.abs(hour) < 24) {
        return Math.floor(hour) + "h"
    }
    const day = hour / 24
    return Math.floor(day) + "d"
}
