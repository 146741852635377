type PageElement = HTMLElement|JQuery|JQuery.Selector

interface ResponsiveNavigationOptions {
    editButtonElement: PageElement
    scrollUpButtonElement: PageElement
    scrollDownButtonElement: PageElement
    scrollDownNavElement: PageElement
    menubarElement: PageElement
    mainContentsBottomElement: PageElement
    sidebarBottomElement: PageElement
    headerElement: PageElement
    footerElement: PageElement
    overlayAdRenderedEventName: string
    overlayAdMarginTop: number
}

const LAYOUT_BREAKPOINT = 820
const EDIT_MENU_SELECTOR = '#edit-menu' // FIXME

$.fn.extend({
    'responsiveNavigation': function (options) {

        options = $.extend({
            editButtonElement: null,
            scrollUpButtonElement: null,
            scrollDownButtonElement: null,
            scrollDownNavElement: null,
            menubarElement: null,
            mainContentsBottomElement: null,
            sidebarBottomElement: null,
            headerElement: null,
            footerElement: null,
            overlayAdRenderedEventName: null,
            overlayAdMarginTop: null
        }, options) as ResponsiveNavigationOptions

        const $scrollDownButton = $(options.scrollDownButtonElement)
        const $scrollUpButton = $(options.scrollUpButtonElement)
        const $editButton = $(options.editButtonElement)

        // オーバーレイ広告の render が終わったら高さ取得
        // 広告と矢印の位置が近すぎるようなら矢印を上にずらしてやる
        $(document).on(options.overlayAdRenderedEventName, function (e) {
            const scrollDownBottom = parseInt($scrollDownButton.css('bottom').replace(/\D/g, ''))
            const overlayAdSectionHeight = $(e.target).height() + options.overlayAdMarginTop
            if (scrollDownBottom < overlayAdSectionHeight) {
                $scrollDownButton.css('bottom', overlayAdSectionHeight + 'px')
                $scrollUpButton.css('bottom', overlayAdSectionHeight + 50 + 'px')
                $editButton.css('bottom', overlayAdSectionHeight + 50 + 75 + 'px')
            }
        })

        $editButton.on('click', function(e){
            e.preventDefault()
            $(EDIT_MENU_SELECTOR).toggleClass('on')
        })

        $scrollUpButton.on('click', function(e){
            e.preventDefault()
            $("html,body").animate({
                scrollTop: 0
            }, 200)
        })

        $scrollDownButton.add(options.scrollDownNavElement).on('click', function(e){
            e.preventDefault()
            $("html,body").animate({
                scrollTop: downsideScrollPosition()
            }, 200)
        })

        let focusState = false
        let scrollUpEnabledState = false
        let scrollDownEnabledState = false

        function updateAllButtonVisibility() {
            const upd = ($button:JQuery, shown:boolean) => {
                if (shown) {
                    $button.addClass('shown')
                } else {
                    $button.removeClass('shown')
                }
            }
            upd($editButton, !focusState);
            upd($scrollUpButton, !focusState && scrollUpEnabledState);
            upd($scrollDownButton, !focusState && scrollDownEnabledState);
        }

        $('textarea,input[type="text"],input[type="search"],input[type="password"]').on('focus', () => {
            focusState = true
            updateAllButtonVisibility()
        }).on('blur', () => {
            focusState = false
            updateAllButtonVisibility()
        })
        $(window).on('codemirror-focus', () => {
            focusState = true
            updateAllButtonVisibility()
        }).on('codemirror-blur', () => {
            focusState = false
            updateAllButtonVisibility()
        })
        updateAllButtonVisibility()

        if (
            !!window.IntersectionObserver &&
            !/iPhone|iPad|iPod/.test(navigator.userAgent) // 現状 iOS では挙動不審なので
        ) {
            withIntersectionObserver()
        } else {
            withoutIntersectionObserver()
        }

        function withIntersectionObserver() {
            const forNarrowDevice = {
                callback(changes: IntersectionObserverEntry[]) {
                    const clientHeight = document.documentElement.clientHeight
                    changes.forEach(change => {
                        const rect = change.target.getBoundingClientRect()
                        const h = (0 < rect.top && rect.top < clientHeight)
                            || (0 < rect.bottom && rect.bottom < clientHeight)
                            || (0 > rect.top && rect.bottom > clientHeight)
                        if (isNarrowDevice()) {
                            scrollDownEnabledState = !h
                        }
                    })
                    updateAllButtonVisibility()
                },
                target: document.querySelector(options.menubarElement),
            }

            const forHeader = {
                callback(changes: IntersectionObserverEntry[]) {
                    changes.forEach(change => {
                        scrollUpEnabledState = !change.isIntersecting
                    })
                    updateAllButtonVisibility()
                },
                target: document.querySelector(options.headerElement),
            }

            const forFooter = {
                callback(changes: IntersectionObserverEntry[]) {
                    changes.forEach(change => {
                        if (!isNarrowDevice() || !$(options.menubarElement).length) {
                            scrollDownEnabledState = !change.isIntersecting
                        }
                    })
                    updateAllButtonVisibility()
                },
                target: document.querySelector(options.footerElement),
            }

            const observerEntries = [forNarrowDevice, forHeader, forFooter]
            observerEntries.forEach(entry => {
                const observer = new IntersectionObserver(entry.callback)
                if (entry.target) {
                    observer.observe(entry.target)
                }
            })
        }

        function withoutIntersectionObserver() {
            const $window = $(window)
            const $menubar = $(options.menubarElement)
            const $header = $(options.headerElement)

            const thresholdTop = $header.length ? $header.offset().top + $header.height() : 0

            const updateScrollButtons = () => {
                scrollUpEnabledState = $window.scrollTop() > thresholdTop

                if ($menubar.length) {
                    scrollDownEnabledState = $window.scrollTop() <= $menubar.offset().top - $window.height()
                } else {
                    const $footer = $(options.footerElement)
                    const thresholdBottom = $footer.length
                        ? $footer.offset().top - $window.height()
                        : document.documentElement.clientHeight - $window.height()
                    scrollDownEnabledState = $window.scrollTop() <= thresholdBottom
                }

                updateAllButtonVisibility()
            }

            updateScrollButtons()
            $window.on('scroll', function () {
                updateScrollButtons()
            })
        }

        function isNarrowDevice() {
            return window.innerWidth < LAYOUT_BREAKPOINT
        }

        function downsideScrollPosition() {
            const backScrollMarginMin = 200
            const backScrollMarginMax = 500
            const enoughHeightToAssumeContentAvailable = 32

            let position = document.documentElement.scrollHeight - document.documentElement.clientHeight

            // Desktop size
            const $footer = $(options.footerElement)
            if ($footer.length) {
                position = $footer.offset().top - backScrollMarginMin
            }
            if (!isNarrowDevice()) {
                return position
            }

            // Mobile size
            const $menubar = $(options.menubarElement)
            if ($menubar.length) {
                // メニューバーより少し上を狙ってスクロールさせる。
                // ユーザー利便性を考えると、画面最上位より少し下がった位置のほうが認識しやすい。
                // というのと、ぴったりな位置になる場合と広告が入ってくる場合とが明確に分かれると白々しいので。
                const menubarBasedPos = $menubar.offset().top - backScrollMarginMin
                if (menubarBasedPos < position) {
                    position = menubarBasedPos
                }
            }

            // MenuBar が見えるという機能を満たす基本位置から離れられる限界までなら、広告を貪欲に見せるために位置を引き上げていい
            const basicFunctionalPos = position
            const backScrollLimit = basicFunctionalPos - (backScrollMarginMax - backScrollMarginMin)

            const pullUpTargetSelectors = [
                options.sidebarBottomElement,
                options.mainContentsBottomElement
            ]
            const pullUpTargetTopMargin = 10

            pullUpTargetSelectors.forEach(selector => {
                const $pullUpTargetArea = $(selector)
                if ($pullUpTargetArea.length && $pullUpTargetArea.height() >= enoughHeightToAssumeContentAvailable) {
                    const pullUpTargetAreaBasedPos = $pullUpTargetArea.offset().top - pullUpTargetTopMargin
                    if (
                        pullUpTargetAreaBasedPos >= backScrollLimit &&
                        pullUpTargetAreaBasedPos < position
                    ) {
                        position = pullUpTargetAreaBasedPos
                    }
                }
            })

            return position
        }
    }
})
