/* ******************************************************************
 *  $Id: listbox3.js v 0.3 2006/03/04 10:45:42 jjyun Exp $
 * -----------------------------------------------------------------
 * Copyright (C) 2006 written by jjyun
 * ( http://www2.g-com.ne.jp/~jjyun/twilight-breeze/pukiwiki.php )
 * ******************************************************************
 */

function _plugin_listbox3_changeMode(listbox, imgPathEdit, imgPathRefer) {
    listbox.select.disabled = !listbox.select.disabled;
    // noinspection JSUnresolvedVariable
    listbox.editTrigger.src = listbox.select.disabled ? imgPathEdit : imgPathRefer;
}

if (module) {
    module.exports = _plugin_listbox3_changeMode;
}
