import {faUseIcons} from './lib/fakit'

$.fn.extend({
    'fontAwesomeLoader': function (this: JQuery) {
        loadAndRefresh(collectIconClasses(this), () => {
            // console.log("loaded")
            this.each(function () {
                const cs = $(this).data('fa-class') ?? ""
                const st = $(this).data('fa-style') ?? null
                const elem = $('<i>').addClass(cs)
                if (st) {
                    elem.attr('style', st)
                }
                $(this).replaceWith(elem)
            })
        })
    }
})

function collectIconClasses(jq: JQuery) {
    const classes = jq.map(function () {
        return $(this).data('fa-class')
    }).toArray()

    return uniqStringArray(classes.map((cs) => {
        return cs.split(/\s+/).filter((c) => {
            return c.match(/^(fa[srb]|fa-.+)$/) !== null
        }).join(" ")
    }))
}

function loadAndRefresh(classes: string[], refresh: ()=>void) {
    faUseIcons(classes).then(refresh)
    // library.reset()
    // dom.watch()
}

function uniqStringArray(array: string[]): string[] {
    const knownElements = {}
    const uniqueArray = []
    for (let i = 0, maxi = array.length; i < maxi; i++) {
        if (array[i] in knownElements) {
            continue
        }
        uniqueArray.push(array[i]);
        knownElements[array[i]] = true
    }
    return uniqueArray
}
