$.fn.extend({
    'accordion': function (this: JQuery, options) {
        options = $.extend({
            headerSelector: '',
            contentSelector: '',
            openClassName: '',
        }, options)

        const $header = this.children(options.headerSelector)

        $header.on('click', function () {
            const $container = $(this).parent()
            $container.children(options.contentSelector).slideToggle('fast', function () {
                $container.toggleClass(options.openClassName)
            })
            return false
        })
    },
})
