$.fn.extend({
    'popular': function (this: JQuery, options) {
        options = $.extend({
            titleComponentSelector: '.wikiwiki-popular-title',
            listComponentSelector: '.wikiwiki-popular-list',
        }, options)

        $(this).each(function () {
            const $target = $(this)
            const endpoint = $target.attr('data-endpoint')
            $.ajax({
                url: endpoint,
                success: function (data) {
                    if (!!data) {
                        updateView($target, data)
                    }
                },
            })
        })

        function updateView($target, data) {
            if (!Array.isArray(data)) {
                return
            }

            const $title = $target.find(options.titleComponentSelector)
            const titleTemplate = $title.attr('data-template')
            $title.text(titleTemplate.replace('{{pages}}', data.length))

            const $list = $target.find(options.listComponentSelector)
            const itemTemplate = $list.attr('data-template')

            $list.find('*').remove()
            $.each(data, function (i, v) {
                let html = itemTemplate

                // スラッシュだけエスケープしない
                const link = v.page.split("/").map(encodeURIComponent).join("/")

                // そんなページ名ないけど驚き最小で
                const page = v.page.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")

                html = html.replace('{{link}}', link)
                html = html.replace('{{page}}', page)
                html = html.replace('{{count}}', v.pv)

                $list.append($(html))
            })
        }
    },
})
