import {formatDate} from './lib/value-format'

$.fn.extend({
    'recentdetail': function (this: JQuery) {
        // no options

        const now = new Date()

        this.each(function () {
            const $container = $(this)
            const boundaries = $container.attr('data-group-boundary').split(',')
            const $listContainer = $container.find('ul')
            const $items = $listContainer.find('li')

            // 配列に入れ直し、タイムスタンプによるソートで順序を保障しておく。
            const items = []
            $items.each(function () {
                items.push({
                    timestamp: parseInt($(this).attr('data-timestamp')),
                    element: this,
                })
            })
            items.sort(function (a, b) {
                return b.timestamp - a.timestamp
            })

            let $currentListContainer = null
            let prevHeaderHtml = null
            let renderedSeparator = false
            $.each(items, function (_, item) {
                const itemElement = item.element
                const itemTimestamp = item.timestamp

                const boundary = findBoundaryRangeIncluding(itemTimestamp, boundaries, now)
                const headerHtml = boundary ?
                    relativeTimeBoundaryHeader(boundary) :
                    dailyBoundaryHeader(dateOf(itemTimestamp))

                if (headerHtml !== prevHeaderHtml) {
                    $currentListContainer = $('<ul>')
                    if (boundary === null && renderedSeparator === false) {
                        if (prevHeaderHtml !== null) {
                            $container.append('<hr class="short_line">')
                        }
                        renderedSeparator = true
                    }
                    $container.append($('<div>').html(headerHtml))
                    $container.append($currentListContainer)
                    prevHeaderHtml = headerHtml
                }
                $currentListContainer.append(itemElement)
            })
            $listContainer.remove()
        })
    },
})


function findBoundaryRangeIncluding(itemTimestamp: number, boundaries: string[], now: Date): string|null {
    for (let i in boundaries) {
        const boundary = boundaries[i]
        if (boundary2timestamp(boundary, now) < itemTimestamp) {
            return boundary
        }
    }
    return null
}

function boundary2timestamp(boundary: string, now: Date): number {
    const m = boundary.match(/^([0-9]+)([mhdw])$/)
    if (!m) {
        return 0
    }
    const value = parseInt(m[1])
    const unit = m[2]

    const unitBase = {
        m: 60,
        h: 60 * 60,
        d: 24 * 60 * 60,
        w: 7 * 24 * 60 * 60,
    }

    return Math.floor(now.getTime() / 1000) - value * unitBase[unit]
}

function boundary2label(boundary: string): string {
    const m = boundary.match(/^([0-9]+)([mhdw])$/)
    if (!m) {
        return ""
    }
    const value = parseInt(m[1])
    const unit = m[2]

    const unitLabel = {
        m: "分",
        h: "時間",
        d: "日",
        w: "週間",
    }

    return value + unitLabel[unit]
}

function dateOf(timestamp: number): Date {
    const d = new Date(timestamp * 1000)
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    d.setMilliseconds(0)
    return d
}

function relativeTimeBoundaryHeader(boundary: string): string {
    // noinspection HtmlDeprecatedTag,HtmlDeprecatedAttribute,XmlDeprecatedElement
    return '<strong><font color=#973D21><span class="recentdetail">' +
        boundary2label(boundary) +
        '以内に更新</span></font></strong>'
}

function dailyBoundaryHeader(date: Date): string {
    return '<strong>' + formatDate(date) + '</strong>'
}
